import React from "react";

export type TextProps = { text: string };
const Text: React.FunctionComponent<TextProps> = (props: TextProps) => {
  const txt = props.text;
  const rtxt = txt.split("\n").map((e, index) => {
    return <p key={index}>{e}</p>;
  });
  return <div>{rtxt}</div>;
};
export default Text;
