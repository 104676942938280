import React, { FunctionComponent } from "react";
import { MemberType } from "./Member";
import "./MemberCard.css";
import defaultImage from "../assets/images/default-user-image.png";
import Text from "./Text";
export type MemberCardProps = { member: MemberType };
const MemberCard: FunctionComponent<MemberCardProps> = (
  props: MemberCardProps
) => {
  let member = props.member;
  const mailtoMember = () => {
    let mailto_link = "mailto:" + member.email;
    window.open(mailto_link, "emailWindow");
  };
  return (
    <div className="member-card">
      <div className="container" onClick={mailtoMember}>
        <img
          className="profile_picture"
          src={
            member.profile_picture
              ? require("../assets/images/members/" + member.profile_picture)
              : defaultImage
          }
        />
        <div className="name">{member.name}</div>
        <div className="about_me">
          <Text text={member.about_me} />
        </div>
      </div>
    </div>
  );
};

export default MemberCard;
