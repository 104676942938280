import React, { ReactElement, useEffect, useRef } from "react";
import "./Map.css";
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import Text from "./Text";

const render = (status: Status): ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function MapComponent({
  center,
  zoom,
}: {
  center: google.maps.LatLngLiteral;
  zoom: number;
}) {
  const ref = useRef();

  useEffect(() => {
    const map = new google.maps.Map(ref.current, {
      center,
      zoom,
    });
    const marker = new google.maps.Marker({
      position: { lat: 19.384154333302288, lng: -99.1487493762052 },
      map: map,
    });
  });

  return <div ref={ref} id="map" className="map-component" />;
}

const Map = () => {
  const center = { lat: 19.384154333302288, lng: -99.1487493762052 };
  const zoom = 16;
  return (
    <Wrapper apiKey={"AIzaSyCcOrP0Ox1zc48h4B6Gx0JYPYru7Hl1Zf4"} render={render}>
      <div className="map">
        <div className="container">
          <div className="map-title">
            <a href="mailto: vicky@erosion.dev" className="link">
              Email us
            </a>{" "}
            or Visit
          </div>
          <MapComponent center={center} zoom={zoom} />
        </div>
      </div>
    </Wrapper>
  );
};
export default Map;
