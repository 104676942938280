import React from "react";
import logo from "./assets/images/01 Color/Slogan/logoslogan-erosion-color-G.png";
import "./App.css";
import SectionTitle from "./components/SectionTitle";
import team from "./data/members.json";
import MemberList from "./components/MemberList";
import Section from "./components/Section";
import Map from "./components/Map";
import HeaderFooter from "./components/HeaderFooter";
function App() {
  return (
    <div className="App">
      <HeaderFooter />
      <header className="App-header">
        <div className="space" />
        <img src={logo} className="App-logo" alt="logo" />
        <div className="space" />
      </header>
      <Section>
        <Section>
          <SectionTitle title="Meet the team" />
          <MemberList members={team.members} />
        </Section>
        <Section>
          <SectionTitle title="Contact us" />
          <Map />
        </Section>
      </Section>
      <HeaderFooter />
    </div>
  );
}

export default App;
