import React from "react";
import { MemberType } from "./Member";
import MemberCard from "./MemberCard";
import "./MemberList.css";
export type MemberListProps = { members: MemberType[] };
const MemberList = (props: MemberListProps) => {
  let members = props.members.map((m, index) => {
    return (
      <div key={index}>
        <MemberCard member={m} />
        <div style={{ height: 20 }}></div>
      </div>
    );
  });
  return <div className="member-list horizontal">{members}</div>;
};

export default MemberList;
