import React, { FunctionComponent } from "react";
import "./SectionTitle.css";
export type SectionTitleProps = {
  title: string;
};
const SectionTitle: FunctionComponent<SectionTitleProps> = (
  props: SectionTitleProps
) => {
  let title = props.title ? props.title : "Title Missing";
  return (
    <div className="SectionTitle container">
      <div className="title">{title}</div>
    </div>
  );
};

export default SectionTitle;
