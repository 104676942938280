import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";
import "./Section.css";
export type SectionProps = {} & PropsWithChildren;

const Section: FunctionComponent<SectionProps> = (props) => {
  let children = props.children;
  if (!Array.isArray(children)) {
    return <div>{children}</div>;
  }
  let spacedChildren: ReactNode[] = [];
  (children as ReactElement[]).forEach((e, index) => {
    spacedChildren.push(
      React.cloneElement(e, { key: spacedChildren.length }, e.props.children)
    );
    if (index < (children as ReactNode[]).length - 1) {
      spacedChildren.push(
        <div className="space" key={spacedChildren.length} />
      );
    }
  });
  return <div>{spacedChildren}</div>;
};

export default Section;
